import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import './styles.scss'

const Assurance = loadable(() => import('/src/components/Basic/Assurance'))
const ContentCard = loadable(() => import('/src/components/Cards/ContentCard'))
const ContentDisclaimer = loadable(() => import('/src/components/Basic/ContentDisclaimer'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))

const InsuranceHeroLeadGenJourney = loadable(() => import('/src/forms/LeadGen/InsuranceHeroLeadGenJourney'))
const SinglePageLeadGenJourney = loadable(() => import('/src/forms/LeadGen/SinglePageLeadGenJourney'))

const SinglePageJourneyBlock = ({ title, formSelect, disclaimer }) => {
    const journeys = {
        standard: SinglePageLeadGenJourney,
        'insurance-hero': InsuranceHeroLeadGenJourney
    }

    const Journey = journeys[formSelect]

    return (
        <div id="form">
            <Section size={'sm'} className={`c-single-page-journey-block c-single-page-journey-block--${formSelect}`}>
                <div className="row">
                    <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                        <ContentCard variant={'no-padding'}>
                            <Journey title={title} />
                        </ContentCard>

                        <div className="c-single-page-journey-block__disclaimer">
                            <Assurance />

                            <div className="c-single-page-journey-block__disclaimer-smallprint">
                                <ContentDisclaimer
                                    content={`By clicking "Get My Quotes", you agree to be contacted by email and telephone by an FCA authorised insurance firm and confirm that you have read and agreed to our <a href="/terms-conditions">Terms &amp; Conditions</a> and <a href="/privacy-policy">Privacy Policy</a>.`}
                                />
                            </div>

                            {disclaimer && <ContentDisclaimer content={disclaimer} />}
                        </div>
                    </div>
                </div>
            </Section>
        </div>
    )
}

SinglePageJourneyBlock.propTypes = {
    title: PropTypes.string.isRequired,
    formSelect: PropTypes.oneOf(['standard', 'insurance-hero']).isRequired,
    disclaimer: PropTypes.string
}

SinglePageJourneyBlock.defaultProps = {
    title: 'Section Title',
    formSelect: 'standard'
}

export default SinglePageJourneyBlock

export const query = graphql`
    fragment SinglePageJourneyBlockForPageBuilder on WpPage_Acfpagebuilder_Layouts_SinglePageJourneyBlock {
        fieldGroupName
        title
        formSelect
        disclaimer
    }
`
